import { FC, useCallback, useMemo } from 'react';

import { SelectValue } from '@mui/base';
import { SelectChangeEventType } from '@mui/base/useSelect/useSelect.types';
import { QuickSearchHeader } from '@vakantiesnl/components/src/__LEGACY__/molecules/QuickSearchHeader';
import { Button } from '@vakantiesnl/components/src/atoms/Button';
import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import { InputAdornmentComponent } from '@vakantiesnl/components/src/atoms/InputAdornment';
import { Link } from '@vakantiesnl/components/src/atoms/Link';
import { SelectFormFieldCalendar } from '@vakantiesnl/components/src/molecules/SelectFormField';
import { SelectFormField } from '@vakantiesnl/components/src/molecules/SelectFormField/OldSelectFormField';
import { TextfieldComponentProps } from '@vakantiesnl/components/src/molecules/TextField';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { useParseDurationsFilterConfig } from '@vakantiesnl/services/src/hooks/filter-hooks';
import { useGetCountries } from '@vakantiesnl/services/src/hooks/queries';
import { useQuickSearch } from '@vakantiesnl/services/src/hooks/rest/quick-search/hook';
import { useGlobalFiltersStore } from '@vakantiesnl/services/src/stores';
import { isNotEmpty } from '@vakantiesnl/services/src/util/arrayUtils';
import { SearchBlock } from '@vakantiesnl/types';
import { noop } from 'lodash';

import { useCustomStyles } from './QuickSearch.style';
export interface SelectOptionDefinition<Value> {
	value: Value;
	disabled?: boolean;
	label: string;
}

type Props = {
	headerImage: Optional<string>;
	data: SearchBlock;
};

const QuickSearch: FC<Props> = ({ headerImage, data }) => {
	const setDurations = useGlobalFiltersStore((s) => s.setDurations);
	const [{ url }, { setQuickSearchCountry, setQuickSearchDepartureDate }] = useQuickSearch();
	const allCountries = useGetCountries();
	const { classes } = useCustomStyles();

	const selectDepartureDate = useCallback(
		(date: string[]) => {
			const dateToStore = new Date(date?.[0] || '');
			setQuickSearchDepartureDate(dateToStore);
		},
		[setQuickSearchDepartureDate],
	);

	const microCopies = useMicroCopyContext();

	const allRanges = useParseDurationsFilterConfig(microCopies);
	const flattenRanges: string[] = allRanges.reduce(
		(prev: string[], option) => [...prev, ...option.value.split(',')],
		[],
	);

	const durationDaysOptions = flattenRanges
		? flattenRanges.map((day: string) => ({
				label: `${day} ${microCopies['common.days']}`,
				value: day,
			}))
		: [];

	const onSelectCountry = useCallback(
		(_: SelectChangeEventType, options: SelectValue<string, true>) => {
			if (!allCountries.data) return;

			const selectedCountries = options
				.map((a) => allCountries.data.find((c) => c.slug === a))
				.filter(isNotEmpty);
			setQuickSearchCountry(selectedCountries);
		},
		[setQuickSearchCountry, allCountries.data],
	);

	const onSelectDuration = useCallback(
		(_: SelectChangeEventType, options: SelectValue<string, true>) => {
			const durations = [...options].map((item) => parseInt(item)).sort((a, b) => a - b);
			setDurations(durations);
		},
		[setDurations],
	);

	const countryOptions = useMemo((): SelectOptionDefinition<string>[] => {
		if (!allCountries.data?.length) return [];

		return allCountries.data
			.filter((c) => !c.isDisabled)
			.map((country) => ({
				value: country.slug,
				label: country.name,
			}));
	}, [allCountries.data]);

	const airportsInputProps: TextfieldComponentProps['InputProps'] = useMemo(
		() => ({
			startAdornment: (
				<InputAdornmentComponent position="start" type="icon">
					<Icon type="airplane" />
				</InputAdornmentComponent>
			),
		}),
		[],
	);

	const durationsInputProps: TextfieldComponentProps['InputProps'] = useMemo(
		() => ({
			startAdornment: (
				<InputAdornmentComponent position="start" type="icon">
					<Icon type="time" />
				</InputAdornmentComponent>
			),
		}),
		[],
	);

	return (
		<QuickSearchHeader
			backgroundImgSrc={headerImage}
			backgroundImgName={headerImage}
			title={data.title}
			quickSearchTitle={data.suggestion}
		>
			<SelectFormField
				microCopies={microCopies}
				placeholder={microCopies['quickSearch.placeholder']}
				options={countryOptions}
				onChange={onSelectCountry}
				variant="checkbox"
				multiple={true}
				InputProps={airportsInputProps}
				label={''}
				listBoxClassName={classes.listBox}
			/>
			<SelectFormFieldCalendar
				setDepartureDate={selectDepartureDate}
				departureDate={null}
				flexDays={false}
				setFlexDays={noop}
				hasDrawerOnMobileTablet={false}
				hasFlexibilityOptions={false}
				dataCy="searchPanelDatePicker"
			/>
			<SelectFormField
				dataCy="searchBarDurationSelect"
				microCopies={microCopies}
				placeholder={microCopies['filters.noPreference']}
				options={durationDaysOptions}
				onChange={onSelectDuration}
				variant="checkbox"
				multiple={true}
				InputProps={durationsInputProps}
				label={''}
				listBoxClassName={classes.listBox}
			/>
			<Link href={url.href} as={url.as}>
				<Button variant={data.cta.variant} trailingIcon="arrowRight" asText>
					{data.cta.title}
				</Button>
			</Link>
		</QuickSearchHeader>
	);
};

export default QuickSearch;
