import { GeoLocation } from '@vakantiesnl/types/src/geo';

import { SET_QUICKSEARCH_COUNTRY, SET_QUICKSEARCH_DEPARTURE_DATE } from './constants';

type SetQuickSearchCountry = {
	type: typeof SET_QUICKSEARCH_COUNTRY;
	payload: GeoLocation[];
};

type SetQuickSearchDepartureDate = {
	type: typeof SET_QUICKSEARCH_DEPARTURE_DATE;
	payload: Date;
};

export const setQuickSearchCountry = (payload: GeoLocation[]): SetQuickSearchCountry => ({
	type: SET_QUICKSEARCH_COUNTRY,
	payload,
});

export const setQuickSearchDepartureDate = (payload: Date): SetQuickSearchDepartureDate => ({
	type: SET_QUICKSEARCH_DEPARTURE_DATE,
	payload,
});

export type ActionTypes = SetQuickSearchCountry | SetQuickSearchDepartureDate;
