import { ActionTypes } from './actions';
import { RECEIVE_ERROR, RECEIVE_RESPONSE, RESET_STATE, SET_DATA, SET_ERROR, SET_LOADING } from './constants';

export type State<DataType> = {
	data: DataType | null;
	error: Error | null;
	loading: boolean;
};

export const initialState: State<unknown> = {
	data: null,
	error: null,
	loading: false,
};

export const reducer = <DataType>(
	state: State<DataType> = initialState as State<DataType>,
	data: ActionTypes<DataType>,
): State<DataType> => {
	switch (data.type) {
		case SET_DATA: {
			return { ...state, data: data.payload };
		}
		case SET_ERROR: {
			return { ...state, error: data.payload };
		}
		case SET_LOADING: {
			return { ...state, loading: data.payload };
		}
		case RECEIVE_ERROR: {
			return {
				data: null,
				error: data.payload,
				loading: false,
			};
		}
		case RECEIVE_RESPONSE: {
			return {
				data: data.payload,
				error: null,
				loading: false,
			};
		}
		case RESET_STATE: {
			return initialState as State<DataType>;
		}
		default: {
			return state;
		}
	}
};
