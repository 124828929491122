import { useMemo } from 'react';

import { SelectOptionDefinition } from '@mui/base/useSelect/useSelect.types';
import { OptionProp } from '@vakantiesnl/components/src/__LEGACY__/atoms/SelectBox';
import { MicroCopy } from '@vakantiesnl/types';

export const useParseDurationsFilterConfig = (microCopies: MicroCopy): OptionProp[] =>
	useMemo(() => {
		if (microCopies['config.filters.durationRanges']) {
			const stripValuePattern = /( \${[\d,]*})/;
			const matchValuePattern = /(?:\${[\d,]*})/;
			const bracketsCapturePattern = /([\$\{\}])/g;

			return microCopies['config.filters.durationRanges'].split(', ').map((durationRange: string) => {
				const matches = durationRange.match(matchValuePattern);
				const value = matches ? matches[0] : '';

				return {
					title: durationRange.replace(stripValuePattern, ''),
					value: value.replace(bracketsCapturePattern, ''),
				};
			});
		}
		return [];
	}, [microCopies]);

export const useParseDurationsFilterConfigLister = (microCopies: MicroCopy): SelectOptionDefinition<string>[] =>
	useMemo(() => {
		if (microCopies['config.filters.durationRanges']) {
			const stripValuePattern = /( \${[\d,]*})/;
			const matchValuePattern = /(?:\${[\d,]*})/;
			const bracketsCapturePattern = /([\$\{\}])/g;

			return microCopies['config.filters.durationRanges'].split(', ').map((durationRange: string) => {
				const matches = durationRange.match(matchValuePattern);
				const value = matches ? matches[0] : '';

				return {
					label: durationRange.replace(stripValuePattern, ''),
					value: value.replace(bracketsCapturePattern, ''),
				};
			});
		}
		return [];
	}, [microCopies]);
