import { RECEIVE_ERROR, RECEIVE_RESPONSE, RESET_STATE, SET_DATA, SET_ERROR, SET_LOADING } from './constants';

type SetStateAction<DataType> = {
	type: typeof SET_DATA;
	payload: DataType;
};

type SetErrorAction = {
	type: typeof SET_ERROR;
	payload: Error;
};

type SetLoadingAction = {
	type: typeof SET_LOADING;
	payload: boolean;
};

type ReceiveErrorAction = {
	type: typeof RECEIVE_ERROR;
	payload: Error;
};

type ReceiveResponseAction<DataType> = {
	type: typeof RECEIVE_RESPONSE;
	payload: DataType;
};

type ResetStateAction = {
	type: typeof RESET_STATE;
};

export const setState = <DataType>(payload: DataType): SetStateAction<DataType> => ({
	type: SET_DATA,
	payload,
});

export const setError = (payload: Error): SetErrorAction => ({
	type: SET_ERROR,
	payload,
});

export const setLoading = (payload: boolean): SetLoadingAction => ({
	type: SET_LOADING,
	payload,
});

export const setReceiveError = (payload: Error): ReceiveErrorAction => ({
	type: RECEIVE_ERROR,
	payload,
});

export const setReceiveResponse = <DataType>(payload: DataType): ReceiveResponseAction<DataType> => ({
	type: RECEIVE_RESPONSE,
	payload,
});

export const setResetState = (): ResetStateAction => ({
	type: RESET_STATE,
});

export type ActionTypes<DataType> =
	| SetStateAction<DataType>
	| SetErrorAction
	| SetLoadingAction
	| ReceiveErrorAction
	| ReceiveResponseAction<DataType>
	| ResetStateAction;
