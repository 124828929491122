import { useCallback, useMemo, useReducer } from 'react';

import { Meta, SearchUrl, useSearchUrlBuilder } from '@vakantiesnl/services/src/hooks/useSearchUrlBuilder';
import { useGlobalFiltersStore } from '@vakantiesnl/services/src/stores';
import { getDateYMD } from '@vakantiesnl/services/src/util/dateUtils';
import { GeoLocation } from '@vakantiesnl/types/src/geo';

import * as actions from './actions';
import { initialState, reducer, State } from './reducer';

export type QuickSearchState = State & { url: SearchUrl };

export type DispatchActions = {
	setQuickSearchCountry: (payload: GeoLocation[]) => void;
	setQuickSearchDepartureDate: (payload: Date) => void;
};

export const useQuickSearch = (): [QuickSearchState, DispatchActions] => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const setQuickSearchCountry = useCallback(
		(payload: GeoLocation[]) => dispatch(actions.setQuickSearchCountry(payload)),
		[dispatch],
	);

	const globalFilters = useGlobalFiltersStore((s) => s.filters);

	const setQuickSearchDepartureDate = useCallback(
		(payload: Date) => dispatch(actions.setQuickSearchDepartureDate(payload)),
		[dispatch],
	);

	const meta: Meta = {};
	if (state.countries) {
		meta.countries = state.countries;
	}

	/**
	 * Check if the state durations are not equal the the initial state.
	 * and pass them as a string[]
	 */
	if (globalFilters.durations) {
		meta.durations = globalFilters.durations;
	}

	if (state.departureDate) {
		meta.departure_date = [getDateYMD(state.departureDate)];
	}

	const url = useSearchUrlBuilder(meta);

	const dispatchActions: DispatchActions = useMemo(
		() => ({
			setQuickSearchCountry,
			setQuickSearchDepartureDate,
		}),
		[setQuickSearchCountry, setQuickSearchDepartureDate],
	);

	return [
		{
			...state,
			url,
		} as QuickSearchState,
		dispatchActions,
	];
};
