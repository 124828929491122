import { alpha } from '@mui/material/styles';
import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles<
	void,
	'row' | 'alertDescription' | 'alertTitle' | 'column' | 'alertChildren' | 'alertIcon'
>()((theme, _, classes) => ({
	alert: {
		borderRadius: theme.shape.borderRadius,
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2.66),
		marginTop: theme.spacing(2.66),
	},
	alertIcon: {
		width: `20px`,
		height: `20px`,
		flexShrink: 0,
		marginRight: theme.spacing(2),
	},
	alertText: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		lineHeight: 1.57,
		[`&.${classes.row}`]: {
			flexDirection: 'row',
			alignItems: 'center',

			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
			[`& .${classes.alertDescription}`]: {
				marginRight: '3px',
			},
			[`& .${classes.alertTitle}`]: {
				marginBottom: 0,
				marginRight: theme.spacing(1),
			},
		},
		[`&.${classes.column}`]: {
			flexDirection: 'column',
		},
	},
	alertTitle: {
		margin: 0,
		marginBottom: theme.spacing(0.5),
	},
	alertChildren: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginRight: theme.spacing(1),
		},
	},
	alertDescription: {
		margin: 0,
	},
	content: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
		alignItems: 'center',
	},
	warning: {
		backgroundColor: alpha(theme.palette.warning.light, 0.1),

		[`& .${classes.alertTitle}`]: {
			color: theme.palette.warning.main,
		},
		[`& .${classes.alertIcon}`]: {
			fill: theme.palette.warning.light,
		},
	},
	info: {
		backgroundColor: alpha(theme.palette.primary.main, 0.1),

		[`& .${classes.alertTitle}`]: {
			color: theme.palette.primary.main,
		},
		[`& .${classes.alertIcon}`]: {
			color: theme.palette.primary.main,
		},
	},
	row: {},
	column: {},
}));

export default useStyles;
