import { FC, ReactNode } from 'react';

import { Search } from '@vakantiesnl/types';
import classNames from 'classnames';

import styles from './SearchResults.module.css';

type RenderSearchResults = {
	accommodation: Search.CompactAccommodationItem | number;
	index: number;
};

export type Props = {
	accommodations: (Search.CompactAccommodationItem | number)[];
	recommendations?: boolean;
	renderResult: (props: RenderSearchResults) => ReactNode;
};

const SearchResults: FC<Props> = ({ renderResult, ...props }) => (
	<div
		className={classNames(
			styles.searchResultsContainer,
			props.recommendations && styles.searchResultsContainerRecommendations,
		)}
	>
		<div className={classNames(styles.searchResults, props.recommendations && styles.searchResultsRecommendations)}>
			{props.accommodations &&
				props.accommodations.map((accommodation, key) =>
					renderResult({
						accommodation,
						index: key,
					}),
				)}
		</div>
	</div>
);

export { SearchResults };
