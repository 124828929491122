import React, { FC, useCallback, useMemo } from 'react';

import { Alert } from '@vakantiesnl/components/src/__LEGACY__/atoms/Alert';
import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { SearchResults } from '@vakantiesnl/components/src/__LEGACY__/organisms/SearchResults';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { useRecommendationsContext } from '@vakantiesnl/services/src/context/useRecommendationsContext';
import { useGetVaknlSearch } from '@vakantiesnl/services/src/hooks/queries';
import {
	FilterState,
	FilterStoreProvider,
	initialFilterState,
	useFilterStore,
} from '@vakantiesnl/services/src/stores/filtersStore';
import { Search } from '@vakantiesnl/types';

import styles from './RecommendationsContainer.module.css';
import { ProductCard } from '../product-card';

type RenderProps = {
	accommodation: Search.CompactAccommodationItem | number;
	index: number;
};

type RecommendationsContainerProps = {
	heading: string;
};

const Recommendations: FC<{ heading: string }> = ({ heading }) => {
	const microCopies = useMicroCopyContext();
	const pageSize = useFilterStore((s) => s.filters.size);

	const { searchData, isSearchLoading, hasSearchError } = useGetVaknlSearch();

	const renderResult = useCallback(
		(props: RenderProps): JSX.Element => (
			<ProductCard key={props.index} {...props} microCopies={microCopies} pageType="homePage" />
		),
		[microCopies],
	);

	if (isSearchLoading || searchData?.accommodations === null) {
		return (
			<div className={styles.recommendationsContainer}>
				<Heading textAlign="center" headingLevel="h2">
					{heading}
				</Heading>
				<SearchResults
					accommodations={Array.from(Array(pageSize).keys())}
					renderResult={renderResult}
					recommendations
				/>
			</div>
		);
	}

	if (hasSearchError) {
		return <Alert description={microCopies['search.error.fetching_accommodation']} variant="warning" />;
	}

	if (!searchData || searchData?.accommodations.length === 0) {
		return null;
	}

	return (
		<div className={styles.recommendationsContainer}>
			<Heading textAlign="center" headingLevel="h2">
				{heading}
			</Heading>
			<SearchResults accommodations={searchData.accommodations} renderResult={renderResult} recommendations />
		</div>
	);
};

const RecommendationsContainer: FC<RecommendationsContainerProps> = ({ heading }) => {
	const microCopies = useMicroCopyContext();
	const {
		state: { data, loading, error },
	} = useRecommendationsContext();

	const renderResult = useCallback(
		(props: RenderProps): JSX.Element => (
			<ProductCard key={props.index} {...props} microCopies={microCopies} pageType="homePage" />
		),
		[microCopies],
	);

	const filters: FilterState = useMemo(
		() => ({
			...initialFilterState,
			giata_ids: data?.giata_ids,
			size: Number(data.page_size),
		}),
		[data?.giata_ids, data.page_size],
	);

	if (loading || data?.giata_ids === null || error || data?.giata_ids?.length === 0)
		return (
			<div className={styles.recommendationsContainer}>
				<Heading textAlign="center" headingLevel="h2">
					{heading}
				</Heading>
				<SearchResults
					accommodations={Array.from(Array(parseInt(data.page_size)).keys())}
					renderResult={renderResult}
					recommendations
				/>
			</div>
		);

	return (
		<FilterStoreProvider filters={filters}>
			<Recommendations heading={heading} />
		</FilterStoreProvider>
	);
};

export default RecommendationsContainer;
