import { FC, memo } from 'react';

import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import ImageComponent from '@vakantiesnl/image-component';
import { ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';
import { Asset } from 'contentful';

import css from './UspExpanded.module.css';

export type UspExpandedProps = {
	title: string;
	image?: Asset;
	subtitle?: string;
};

const contentfulParams: ContentfulImageParams = { fm: 'webp' };

const UspExpanded: FC<UspExpandedProps> = ({ title, image, subtitle }) => (
	<div className={css.uspExpanded}>
		<Icon type="vaknlCheck" className={css.icon} />
		<div className={css.uspContent}>
			<div className={css.uspImage}>
				<ImageComponent
					className={css.image}
					height={128}
					width={290}
					src={image?.fields.file.url || ''}
					alt={title}
					useLoader="Contentful"
					contentfulParams={contentfulParams}
				/>
			</div>
			<Heading headingLevel="h4" className={css.uspTitle}>
				{title}
			</Heading>
			<p className={css.uspDescription}>{subtitle}</p>
		</div>
	</div>
);

const memoizedUspExpanded = memo(UspExpanded);

export { memoizedUspExpanded as UspExpanded };
