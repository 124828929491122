import { useEffect, useMemo } from 'react';

import Cookies from 'universal-cookie';

import { DispatchActions, State, useRestResponse } from './rest-response';
import { fetchVakNL } from '../../util/vaknl-fetch';

export type ApiActions<DataType> = DispatchActions<DataType> & Record<string, unknown>;

export const useRestApi = <DataType>(input?: RequestInfo): [State<DataType>, ApiActions<DataType>] => {
	const [restResponseState, restResponseApi] = useRestResponse<DataType>();
	const { receiveError, receiveResponse, setLoading, resetState } = restResponseApi;

	useEffect(() => {
		const abortController = new AbortController();
		let fetching = false;

		const fetchData = async (): Promise<void> => {
			if (!input) {
				resetState();
				return;
			}

			setLoading(true);
			try {
				fetching = true;
				const signal = abortController.signal;

				const response = await fetchVakNL({
					input,
					init: { signal },
					correlationId: new Cookies().get('correlationId'),
				});
				if (response.ok) {
					fetching = false;
					receiveResponse(await response.json());
				} else {
					fetching = false;
					receiveError(new Error(`Error: ${response.status}`));
				}
			} catch (error) {
				if (!abortController.signal.aborted) {
					fetching = false;
					if (error instanceof Error) {
						receiveError(new Error(error.message));
					}
				}
			}
		};

		fetchData();

		return (): void => {
			fetching && abortController.abort();
		};
	}, [input, receiveError, receiveResponse, setLoading, resetState]);

	const api = useMemo(
		() => ({
			...restResponseApi,
		}),
		[restResponseApi],
	);

	return [restResponseState, api];
};
